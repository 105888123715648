.dish-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
  }

  .dish-name {
    font-weight: bold;
    font-size: 20px;
  }

  .left-element {
    width: 80%;
    padding-right: 8px;
  }

  .right-element {
    width: 20%;
    margin-left: 8px;
    text-align: right;
  }
  
  .english-info {
    font-size: 16px;
    display: flex;
    flex-direction: row;
  }
  
  .local-info {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    color: gray;
  }